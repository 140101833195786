<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit, errors }">
      <form class="login" @submit.prevent="handleSubmit(onSubmit)">
        <div class="ym-toptitle-box">
          <fixed-header :threshold="0" fixedClass="ym-toptitle-fixed">
            <div id="toptitle" class="level ym-toptitle">
              <div class="level-left">
                <router-link
                  tag="a"
                  to="/records/customer"
                  class="button xbtn is-medium is-transparent"
                >
                  <icon name="x"></icon>
                </router-link>
                <h2 class="title" v-if="isNew">Nowy klient</h2>
                <h2 class="title" v-else>Edytuj klienta</h2>
              </div>
              <div class="buttons level-right">
                <button class="button is-medium is-primary">
                  <icon name="check"></icon><span>Zapisz</span>
                </button>
              </div>
            </div>
          </fixed-header>
        </div>
        <div
          :class="
            errors['firstname'] && errors['firstname'].length > 0
              ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
              : 'columns is-mobile is-variable is-3 is-multiline'
          "
        >
          <div class="column ym-column is-3">
            <div class="ym-label">
              <label for="f_company_short">Imię</label>
              <span
                v-if="errors['firstname'] && errors['firstname'].length > 0"
                class="help is-danger"
                >{{ errors["firstname"][0] }}</span
              >
            </div>
          </div>
          <div class="column ym-column is-9">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="required" name="firstname">
                  <input
                    name="firstname"
                    v-model="customer.firstname"
                    class="input is-medium is-uppercase"
                  />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="
            errors['lastname'] && errors['lastname'].length > 0
              ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
              : 'columns is-mobile is-variable is-3 is-multiline'
          "
        >
          <div class="column ym-column is-3">
            <div class="ym-label">
              <label for="f_company_short">Nazwisko</label>
              <span
                v-if="errors['lastname'] && errors['lastname'].length > 0"
                class="help is-danger"
                >{{ errors["lastname"][0] }}</span
              >
            </div>
          </div>
          <div class="column ym-column is-9">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="required" name="lastname">
                  <input
                    name="lastname"
                    v-model="customer.lastname"
                    class="input is-medium is-uppercase"
                  />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="
            errors['email'] && errors['email'].length > 0
              ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
              : 'columns is-mobile is-variable is-3 is-multiline'
          "
        >
          <div class="column ym-column is-3">
            <div class="ym-label">
              <label for="f_company_email">Email</label>
              <span
                v-if="errors['email'] && errors['email'].length > 0"
                class="help is-danger"
                >{{ errors["email"][0] }}</span
              >
            </div>
          </div>
          <div class="column ym-column is-9">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider rules="required" name="email">
                  <input
                    name="email"
                    v-model="customer.email"
                    class="input is-medium is-uppercase"
                  />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="
            errors['nip'] && errors['nip'].length > 0
              ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
              : 'columns is-mobile is-variable is-3 is-multiline'
          "
        >
          <div class="column ym-column is-3">
            <div class="ym-label">
              <label for="f_company_nip">NIP</label>
              <p>Aby pobrać dane firmy z bazy GUS</p>
              <span
                v-if="errors['nip'] && errors['nip'].length > 0"
                class="help is-danger"
                >{{ errors["nip"][0] }}</span
              >
            </div>
          </div>
          <div class="column ym-column is-6">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider name="nip">
                  <input
                    class="input is-medium is-uppercase"
                    v-model="customer.nip"
                    @change="testNip"
                    id="f_company_nip"
                    type="text"
                    placeholder=""
                  />
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="column ym-column is-3">
            <a
              @click.prevent="NIPcheck"
              :class="
                customer.nip
                  ? isNIPloading
                    ? 'button is-fullwidth is-medium is-disabled'
                    : 'button is-fullwidth is-medium is-light'
                  : 'button is-fullwidth is-medium is-disabled'
              "
            >
              <span v-if="!isNIPloading">Pobierz dane</span>
              <span v-if="isNIPloading">
                <icon name="loader"></icon>
              </span>
              <icon v-else name="download-cloud"></icon>
            </a>
          </div>
        </div>
        <div
          :class="
            errors['shortname'] && errors['shortname'].length > 0
              ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
              : 'columns is-mobile is-variable is-3 is-multiline'
          "
        >
          <div class="column ym-column is-3">
            <div class="ym-label">
              <label for="f_company_short">Skrócona nazwa klienta</label>
              <span
                v-if="errors['shortname'] && errors['shortname'].length > 0"
                class="help is-danger"
                >{{ errors["shortname"][0] }}</span
              >
            </div>
          </div>
          <div class="column ym-column is-9">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider name="shortname">
                  <input
                    name="shortname"
                    v-model="customer.shortname"
                    class="input is-medium is-uppercase"
                  />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="
            errors['name'] && errors['name'].length > 0
              ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
              : 'columns is-mobile is-variable is-3 is-multiline'
          "
        >
          <div class="column ym-column is-3">
            <div class="ym-label">
              <label for="f_company_full">Pełna nazwa klienta</label>
              <span
                v-if="errors['name'] && errors['name'].length > 0"
                class="help is-danger"
                >{{ errors["name"][0] }}</span
              >
            </div>
          </div>
          <div class="column ym-column is-9">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider name="name">
                  <input
                    name="name"
                    v-model="customer.name"
                    class="input is-medium is-uppercase"
                  />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="
            errors['address'] && errors['address'].length > 0
              ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
              : 'columns is-mobile is-variable is-3 is-multiline'
          "
        >
          <div class="column ym-column is-3">
            <div class="ym-label">
              <label for="f_company_street">Ulica</label>
              <p>Z numerem lokalu</p>
              <span
                v-if="errors['address'] && errors['address'].length > 0"
                class="help is-danger"
                >{{ errors["address"][0] }}</span
              >
            </div>
          </div>
          <div class="column ym-column is-9">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider name="address">
                  <input
                    name="address"
                    v-model="customer.address"
                    class="input is-medium is-uppercase"
                  />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="
            errors['postcode'] && errors['postcode'].length > 0
              ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
              : 'columns is-mobile is-variable is-3 is-multiline'
          "
        >
          <div class="column ym-column is-3">
            <div class="ym-label">
              <label for="f_company_postcode">Kod pocztowy</label>
              <span
                v-if="errors['postcode'] && errors['postcode'].length > 0"
                class="help is-danger"
                >{{ errors["postcode"][0] }}</span
              >
            </div>
          </div>
          <div class="column ym-column is-3">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider name="postcode">
                  <input
                    name="postcode"
                    v-model="customer.postcode"
                    class="input is-medium is-uppercase"
                  />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="
            errors['city'] && errors['city'].length > 0
              ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
              : 'columns is-mobile is-variable is-3 is-multiline'
          "
        >
          <div class="column ym-column is-3">
            <div class="ym-label">
              <label for="f_company_city">Miejscowość</label>
              <span
                v-if="errors['city'] && errors['city'].length > 0"
                class="help is-danger"
                >{{ errors["city"][0] }}</span
              >
            </div>
          </div>
          <div class="column ym-column is-9">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider name="city">
                  <input
                    name="city"
                    v-model="customer.city"
                    class="input is-medium is-uppercase"
                  />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="
            errors['phone'] && errors['phone'].length > 0
              ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
              : 'columns is-mobile is-variable is-3 is-multiline'
          "
        >
          <div class="column ym-column is-3">
            <div class="ym-label">
              <label for="f_company_phone">Telefon</label>
              <span
                v-if="errors['phone'] && errors['phone'].length > 0"
                class="help is-danger"
                >{{ errors["phone"][0] }}</span
              >
            </div>
          </div>
          <div class="column ym-column is-9">
            <div class="field">
              <div class="control is-medium">
                <ValidationProvider name="phone">
                  <input
                    name="phone"
                    v-model="customer.phone"
                    class="input is-medium is-uppercase"
                  />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import FixedHeader from "vue-fixed-header";
import { mapActions, mapGetters } from "vuex";

import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "CustomerForm",
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    FixedHeader,
    ValidationObserver,
    ValidationProvider,
  },
  data: function() {
    return {
      customer: {},
      isNIPloading: false,
    };
  },
  computed: {
    ...mapGetters({
      selectACustomer: "orders/selectACustomer",
    }),
    isNew() {
      return this.id === 0 ? true : false;
    },
  },
  methods: {
    ...mapActions({
      getCustomers: "records/getCustomers",
      getCustomer: "records/getCustomer",
      postCustomer: "records/postCustomer",
      getCeidg: "records/getCeidg",
      disabledSidebar: "setDisabledSidebar",
    }),
    onSubmit() {
      this.postCustomer({ customer: this.customer, id: this.$route.params.id })
        .then((resp) => {
          this.$router.push({
            name: "CustomerDetails",
            params: { id: resp.data.id },
          });
        })
        .catch((error) => {
          this.customer = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    NIPcheck: function() {
      const { nip } = this.customer;

      if (nip) {
        this.isNIPloading = true;
        this.getCeidg({ nip })
          .then((resp) => {
            this.customer = resp;
            this.isNIPloading = false;
          })
          .catch((error) => {
            this.customer = {};
            this.$buefy.toast.open({
              duration: 5000,
              message: error.data.error_description,
              position: "is-bottom",
              type: "is-danger",
            });
          });
      }
    },
    testNip() {
      const sortField = "name",
        sortOrder = "asc",
        page = 1,
        perPage = 50,
        searchDeleted = 0,
        searchQuery = this.customer.nip;

      if (searchQuery.length > 9) {
        this.getCustomers({
          page,
          perPage,
          sortField,
          sortOrder,
          searchQuery,
          searchDeleted,
        }).then((resp) => {
          if (resp.data.length > 0) {
            alert("FIrma o tym NIP istnieje już w bazie.");
          }
        });
      }
    },
  },
  mounted() {
    this.disabledSidebar(true);
    if (!this.isNew) {
      this.getCustomer({ id: this.$route.params.id })
        .then((resp) => (this.customer = resp))
        .catch((error) => {
          this.customer = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    }
  },
  destroyed() {
    if (!this.selectACustomer) {
      this.disabledSidebar(false);
    }
  },
};
</script>
